<template>
  <div class="req-temp-container product-field-list mt-6 legals-custom">
    <h3 class="text-xl md:text-2xl lg:text-3xl font-medium page-head-title mb-10 mt-4 md:mt-6 pb-0">{{ product.productName }}</h3>
    <div class="productdetail 2md:flex gap-x-6">
      <div v-if="showProductDetails" class="product">
        <vs-card class="md:w-80 py-8 pl-6 pr-8 ds-wrapper">
          <div>
            <label class="text-body-dark mb-1 ancillary-caps inline-block">Product ID</label>
            <p class="text-shade-black">{{ product.customPlanId }}</p>
          </div>
          <div>
            <label class="text-body-dark mb-1 ancillary-caps inline-block">Minimum amount</label>
            <p class="text-shade-black">${{ addZeroes(product.minimumAmount) }}</p>
          </div>
          <div>
            <label class="text-body-dark mb-1 ancillary-caps inline-block">Maximum amount</label>
            <p class="text-shade-black">${{ addZeroes(product.maximumAmount) }}</p>
          </div>
        </vs-card>
      </div>
      <div class="w-full custom-quillEditor quill-editor">
        <vs-card class="w-full ds-wrapper pt-6 px-8 pb-10 mb-0 terms-card" :class="checkProductFields ? 'rounded-br-none rounded-bl-none' : ''" v-if="checkIntegrationGuide">
          <h3 class="mb-8 text-shade-black">{{ integrationGuide.articleName }}</h3>
          <div class="terms-view-content ql-editor" v-html="integrationGuide.articleBody"></div>
        </vs-card>
        <vs-card class="w-full mb-0 pb-10 prod-field" v-if="checkProductFields">
          <vs-table :data="productFields" class="stripes">
            <template slot="thead">
              <vs-th class="font-normal align-bottom head">ID </vs-th>
              <vs-th class="font-normal align-bottom head whitespace-nowrap">Field name</vs-th>
              <vs-th class="font-normal align-bottom head">Type</vs-th>
              <vs-th class="font-normal align-bottom head">Format</vs-th>
              <vs-th class="font-normal align-bottom head">Required</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.customFieldId" class="whitespace-nowrap">{{ tr.customFieldId }}</vs-td>
                <vs-td :data="tr.label" class="whitespace-nowrap">{{ tr.label }}</vs-td>
                <vs-td :data="tr.type" class="whitespace-nowrap"><span class="capitalize">{{ getDataType(tr.type) }}</span></vs-td>
                <vs-td :data="tr.type">{{ getDescription(tr.type) }}</vs-td>
                <vs-td :data="tr.description" class="align-top">{{ tr.reqToSendRequest }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-card>
        <div v-if="checkProductFields" class="ds-wrapper prism">
          <div class="flex cursor-pointer bg-neutral-900 py-4 px-6">
            <div class="flex items-center gap-x-2 ml-auto copy-content py-1 px-2">
              <vs-icon icon="content_copy" class="text-neutral-400" size="15px"></vs-icon>
              <p @click="copyCode()" class="text-sm">Copy payload</p>
            </div>
          </div>
          <div class="prism-code bg-neutral-900 pt-0 px-8 pb-12 rounded-bl-lg rounded-br-lg">
              <pre><Prism class="language-js" language="javascript" style="background:none">{{ paylaterPayload }}</Prism></pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Prism from "vue-prism-component";
import VueAccordion from "../../../components/VueAccordion.vue";
import { mapActions } from "vuex";

export default {
  components:{
    Prism,
    VueAccordion
  },

  data() {
    return {
      product: "",
      productFields: [],
      integrationGuide: {},
      paylaterPayload: {},
      showProductDetails: false
    };
  },

  methods: {
    ...mapActions("paymentPlan", ["fetchPaymentPlanByIdOnlyNameAndFields"]),
    async getPaymentPlansById() {
      this.$vs.loading();
      await this.fetchPaymentPlanByIdOnlyNameAndFields(this.$route.params.id).then((response) => {
        this.product = response.data.data;
        this.productFields = response.data.data.customFields || [];
        this.integrationGuide = response.data.data.integrationGuide || {};
        this.makePayload(this.product);
        this.showProductDetails = true;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showToastMessage(ex.response.data.title,  ex.response.data.message, "error");
      });
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    getDataType(type) {
      let dataType = "";
      switch (type) {
        case "text":
          dataType = "string";
          break;
        case "number":
          dataType = "number";
          break;
        case "currency":
          dataType = "string (currency)";
          break;
        case "date":
          dataType = "string (date)";
          break;
        case "dropdown":
          dataType = "string (Option list)";
          break;
        case "file":
          dataType = "string (base64)";
          break;
        case "address":
          dataType = "object";
          break;
      }

      return dataType;
    },

    getDescription(type) {
      let description = "";
      switch (type) {
        case "text":
          description = "String (any text, number and/or special character)";
          break;
        case "number":
          description = "Number (any integer or floating point i.e. decimals allowed)";
          break;
        case "currency":
          description = "Numbers only, 2 decimal placed required (no less, no more)";
          break;
        case "date":
          description = `Numbers separated by hyphen "-", in format "YYYY-MM-DD" only`;
          break;
        case "dropdown":
          description = "String (must match the values provided for this field in the integration guide)";
          break;
        case "file":
          description = "base64, file must be PDF type";
          break;
        case "address":
          description = `Object, each field as a String { "street":"","suburb": "", "state": "", "postcode": "", "country": ""}`;
          break;
      }

      return description;
    },

    getTypeWiseFormat(field) {
      const type = field.type;
      const dropdown = field.dropdownSelectFields;
      let format = "";
      let dataType = "";
      switch (type) {
        case "text":
          format = "The quick brown fox jumps over the lazy dog";
          break;
        case "date":
          format = "2022-02-12";
          break;
        case "dropdown":
          format = this.getDropDownValue(dropdown);
          break;
        case "currency":
          format = "50000.00";
          break;
        case "number":
          format = "30";
          break;
        case "file":
          format = "data:application/pdf;base64,JVBERi0xLjMNCiXi48/TDQoNCjEgMCBvYmoNCjw8DQovVHlwZ==";
          break;
        case "address":
          format = {
            "unit_number":"4454",
            "street": "1 Smith Street",
            "suburb": "Sydney",
            "state":"VLC",
            "postcode": "2000",
            "country": "Australia"
          };
          break;

      }
      return format;
    },

    getDropDownValue(dropdown) {
      if (dropdown && dropdown[0]) {
        return dropdown[0];
      }
      return "";
    },

    makePayload(product) {
      let productFields = product.customFields.map((item) => {
        return {
          fieldId: item.customFieldId,
          value: this.getTypeWiseFormat(item)
        }
      });

      if (product.productConfigType == "THREE_PARTY") {
        this.paylaterPayload = {
          product: [
            {
              productId: `${product.customPlanId}`,
              productFields: productFields
            }
          ]
        };
      } else {
        this.paylaterPayload = {
          product: {
            productId: `${product.customPlanId}`,
            productFields: productFields
          }
        };
      }

      this.paylaterPayload = JSON.stringify(this.paylaterPayload, null, 3);
      this.paylaterPayload = this.paylaterPayload.slice(1, (this.paylaterPayload.length - 1));
    },

    copyCode() {
      navigator.clipboard.writeText(this.paylaterPayload).then(() => {
        this.showToastMessage("Payload copied to clipboard", "", "success");
      }).catch((ex) => {
        this.showToastMessage("Error copying payload", "Unable to copy payload to clipboard. Please try again.", "error");
      });
    },
  },

  mounted() {
    this.getPaymentPlansById();
  },

  computed: {
    checkProductFields() {
      return this.productFields.length;
    },

    checkIntegrationGuide() {
      return Object.keys(this.integrationGuide).length;
    }
  }
};
</script>

